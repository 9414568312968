import { Image, ImageFormat } from '@mechhive/react';
import classNames from 'classnames';
import type { CSSProperties, ComponentProps } from 'react';

type BrandCardProps = {
  title?: string;
  brandSlug: string;
  hoverInteraction?: boolean;
  style?: CSSProperties;
  sizes?: ComponentProps<typeof Image>['sizes'];
  loading?: ComponentProps<typeof Image>['loading'];
  width?: ComponentProps<typeof Image>['width'];
  height?: ComponentProps<typeof Image>['height'];
}

export const BrandCard = ( { brandSlug, title, hoverInteraction = true, style, sizes, loading, width, height }: BrandCardProps ) => {
  return (
    <div
      className={ 'flex flex-col gap-2.5 group' }
      style={ style }>
      <div
        className={ 'relative rounded-md overflow-hidden aspect-[198/121]' }>
        <Image
          className={ 'w-full h-full object-cover' } 
          provider='images'
          src={ `/brand/card/background/${brandSlug}.jpg` }
          formats={ [
            ImageFormat.WebP,
            ImageFormat.JPG
          ] }
          width={ width }
          height={ height }
          sizes={ sizes }
          loading={ loading }
          dprVariants={ [1,2] }
        />
        <div className={ 'absolute inset-0 border-[1.5px] rounded-md border-white/20 z-10' } />
        <div className={ classNames(
          'absolute inset-0 bg-[#0C102E]/30 transition-all duration-100 shadow-[0px_12px_20px_0px_rgba(255,255,255,0.20)_inset]',
          hoverInteraction === true ? 'group-hover:bg-[#0C102E]/[14%] ' : ''
        )  } />
        <div className={ 'absolute inset-0 flex items-center justify-center' }>
          <Image 
            provider='images'
            src={ `/brand/card/logo/${brandSlug}.svg` }
            className={ 'w-20 max-h-16 object-contain' }
            alt={ '' }
            formats={ [
              ImageFormat.WebP,
              ImageFormat.PNG
            ] }
            sizes={ sizes?.map( s => {
              return {
                width: s.width ? s.width / 2 : s.width,
                height: s.height ? s.height / 2 : s.height
              }
            } ) }
            loading={ loading }
            dprVariants={ [1,2] }
          />
        </div>
      </div>
      { title &&
        <p className={ 'text-center text-[#E2E8FF]' }>
          { title }
        </p>
      }
    </div>
  )
}